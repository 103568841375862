






import Vue from 'vue';
import PrivacyPolicy from './PrivacyPolicy.vue'
export default Vue.extend({
    components: { PrivacyPolicy },
});
